<template>
  <ul v-if="searched.length" class="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@l uk-child-width-1-5@xl" data-uk-grid>
    <li v-for="folder in folders" :key="folder.name">
      <div class="uk-panel uk-panel-box uk-text-center" @click.prevent="toggleSelect(folder.name)">
        <div class="uk-panel-teaser uk-position-relative">
          <div class="uk-cover-background uk-position-cover thumbnail-folder"></div>
          <div class="uk-position-center uk-light">
            <span data-uk-icon="icon:folder;ratio:3"></span>
          </div>
          <canvas class="uk-responsive-width uk-display-block" width="1200" height="800"></canvas>
        </div>
        <div class="uk-text-truncate">
          <label>
            <input class="uk-checkbox" type="checkbox" :value="folder.name" v-model="selected" @click.stop />
          </label>
          <a @click.stop="setPath(folder.path, $event)"> {{ folder.name }}</a>
        </div>
      </div>
    </li>

    <li v-for="file in files" :key="file.name">
      <div class="uk-panel uk-panel-box uk-text-center uk-padding-small uk-background-default" style="border: 1px solid #e5e5e5" @click.prevent="toggleSelect(file.name)">
        <div class="uk-panel-teaser uk-position-relative uk-margin-small-bottom">
          <div class="uk-background-contain uk-position-cover uk-background-norepeat" v-if="isImage(file.path)" :style="'background-image:url(' + file.url + ')'"></div>
          <div class="uk-cover-background uk-position-cover pk-thumbnail-file" v-else>
            <div class="uk-position-center">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="80" height="80">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

              </span>
            </div>
          </div>
          <canvas class="uk-responsive-width uk-display-block" width="1200" height="800"></canvas>
        </div>
        <div class="uk-text-nowrap uk-text-truncate">
          <label>
            <input class="uk-checkbox" type="checkbox" :value="file.name" v-model="selected" @click.stop />
            {{ file.name }}
          </label>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    searched: {
      required: true,
      type: Array,
    },
    hideFolders: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    folders() {
      if(this.hideFolders) {
        return []
      }
      return this.searched.filter((file) => {
        return file.mime === 'application/folder'
      })
    },
    files() {
      return this.searched.filter((file) => {
        return file.mime === 'application/file'
      })
    },
  },
  watch: {
    selected() {
      this.$emit('selected', this.getSelected())
    },
  },
  methods: {
    setPath(path) {
      this.$emit('setPath', path)
    },
    getSelected() {
      return this.selected.map((name) => {
        return this.searched.find(s => s.name === name).name
      })
    },
    isSelected(name) {
      return this.selected.indexOf(name.toString()) != -1
    },
    toggleSelect(name) {
      let index = this.selected.indexOf(name)
      ;-1 === index ? this.selected.push(name) : this.selected.splice(index, 1)
    },
    isImage(url) {
      return url.match(/\.(?:gif|jpe?g|png|svg|ico)$/i)
    },
  },
}
</script>

<style>
.uk-panel.uk-panel-box {
  cursor: pointer;
}
</style>